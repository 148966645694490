<template>
    <div>
        <div class="content">
            <div>停车场名称</div>
            <div>{{parkingName}}</div>
        </div>
        <div style="display: flex;flex-direction: row;justify-content: space-around;">
            <van-button type="primary" @click="handleApply">申请管理</van-button>
            <van-button type="primary" @click.native="handleClose">关闭</van-button>
        </div>
    </div>
</template>

<script>
    import {Button, Toast} from "vant";
    import {getParking, unattended} from "../api/interface";

    export default {
        name: "unattended",
        components: {
            [Button.name]: Button,
        },
        data() {
            return {
                parkingId: "",
                parkingName: ""
            }
        },
        created() {
            this.parkingId = this.$route.query.parkingId;
        },
        mounted() {
            getParking(this.parkingId).then((res) => {
                this.parkingName = res.data.data.parkingName;
            })
        },
        methods: {
            handleApply() {
                unattended(this.parkingId).then((res) => {
                    if (res.data.code == "SUCCESS") {
                        Toast.success({
                            message: res.data.message,
                            duration: 2000
                        })
                    }
                })
            },
            handleClose() {
                setTimeout(function () {
                    document.addEventListener(
                        'WeixinJSBridgeReady',
                        function () {
                            this.WeixinJSBridge.call('closeWindow') //安卓手机关闭代码
                        },
                        false
                    )
                    this.WeixinJSBridge.call('closeWindow') //苹果手机关闭代码
                }, 300)
                //以下是普通浏览器关闭代码
                window.opener = null
                window.open('about:blank', '_top').close()
            },
        },
        beforeRouteEnter(to, from, next) {
            // 添加背景色
            document.querySelector('body').setAttribute('style', 'background-color:#f9f9f9')
            next()
        },
        beforeRouteLeave(to, from, next) {
            // 去除背景色
            document.querySelector('body').setAttribute('style', '')
            next()
        }
    }
</script>

<style lang="less" scoped>
    .content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 16px;
        line-height: 50px;
        margin: 35px;
    }

    .van-button {
        width: 120px !important;
        border-radius: 20px;
        text-align: center;
        background: linear-gradient(-30deg, #EC7955, #DC5040);
        border: none;
    }
</style>
